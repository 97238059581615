<template>
  <div>
    <b-modal id="detail-citizen-report" size="lg" hide-footer>
      <template #modal-title> <b>DETAIL PENGADUAN</b> </template>
      <table class="table">
        <tr>
          <td>Tanggal Pengaduan</td>
          <td>:</td>
          <td>{{ data.display_date }}</td>
        </tr>
        <tr>
          <td>Nama Warga</td>
          <td>:</td>
          <td>{{ data.citizen_name }}</td>
        </tr>
        <tr>
          <td>Status Pengaduan</td>
          <td>:</td>
          <td>{{ data.status_name }}</td>
        </tr>
        
        <tr>
          <td>Judul</td>
          <td>:</td>
          <td>{{ data.title }}</td>
        </tr>
        <tr>
          <td>Deskripsi Aduan</td>
          <td>:</td>
          <td>{{ data.description }}</td>
        </tr>
      </table>

      <!-- <table class="table table-dark">
        <tr>
          <th colspan="5" class="text-center">Riwayat Permohonan Surat</th>
        </tr>
        <tr>
          <th>No.</th>
          <th>No. Surat</th>
          <th>Tanggal</th>
          <th>Keperluan</th>
          <th>Aksi</th>
        </tr>
      </table> -->
    </b-modal>
  </div>
</template>

<script>
export default {
    props:{
        data: Object
    }
};
</script>

<style>
</style>