<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pengaduan Masyarakat</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/citizen-report/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-between mt-n3">
              <div class="col-md-8 filter-periode-letters">
                <button
                  class="btn mx-1 btn-timeseries my-1"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries my-1"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries my-1"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries my-1"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries my-1"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>
              <div class="col-md-4">
                <!-- <b-form-select
                  v-model="filter.district"
                  :options="districts"
                ></b-form-select> -->
                <treeselect
                  v-model="filter.category_id"
                  :multiple="false"
                  placeholder="Pilih Kategori"
                  :options="report_category"
                  @input="filterByCategory"
                />
              </div>
              <div class="col-md-8">
                <div class="d-flex">
                  <div class="col-md-12 p-0" v-if="onShowPeriod">
                    <span class="d-none d-sm-block">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="pagination"
                            variant="success"
                            >Cari</b-button
                          >
                          <!-- <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      > -->
                        </template>
                      </b-input-group>
                    </span>
                    <span class="d-block d-sm-none">
                      <div class="row">
                        <div class="col-12 pb-1">
                          <b-input-group>
                            <b-form-input
                              v-model="filter.start_date"
                              type="text"
                              placeholder="Awal"
                              autocomplete="off"
                              readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="filter.start_date"
                                button-only
                                right
                                reset-button
                                locale="id"
                                aria-controls="example-input"
                              ></b-form-datepicker>
                            </b-input-group-append>
                            <template #append>
                              <b-button
                                squared
                                @click="pagination"
                                variant="success"
                                >Cari</b-button
                              >
                              <!-- <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          > -->
                            </template>
                          </b-input-group>
                        </div>
                        <div class="col-12 pt-0">
                          <b-input-group>
                            <b-button squared variant="light">s/d</b-button>
                            <b-form-input
                              v-model="filter.end_date"
                              type="text"
                              placeholder="Akhir"
                              autocomplete="off"
                              readonly
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="filter.end_date"
                                button-only
                                right
                                reset-button
                                locale="id"
                                aria-controls="example-input"
                              ></b-form-datepicker>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <b-input-group>
                  <b-form-input
                    type="text"
                    placeholder="Masukkan Nama Penduduk"
                    v-model="filterbyName"
                  ></b-form-input>
                  <template #append>
                    <b-button squared variant="danger" @click="resetFilter"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
            <!-- <div class="row justify-content-start">
              <div class="col py-0">
                <b-button variant="danger" size="sm">
                  <i class="fas fa-file-pdf text-white px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="ml-1"
                  style="background-color: green"
                >
                  <i class="fas fa-file-excel text-white px-0"></i>
                </b-button>
              </div>
            </div> -->

            <!-- time series filter -->

            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              class="mt-3"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>

              <template #cell(status_name)="data">
                <b-badge
                  pill
                  :variant="data.item.variant"
                  style="cursor: pointer"
                  @click="statusOnClick(data.item.status, data.item.id)"
                  >{{ data.item.status_name }}</b-badge
                >
              </template>

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="showModal(data.item)"
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="btnAccess"
                  @click="$router.push(`/citizen-report/edit/${data.item.id}`)"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="btnAccess"
                  @click="deleteData(data.item.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-4"
              v-model="currentPage"
              :total-rows="totalRows"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <ModalDetail :data="detail" />

    <b-modal id="modal-status" hide-footer title="Ubah Status Pengaduan">
      <div class="row">
        <div class="col-md-10">
          <treeselect
            v-model="newStatus"
            :multiple="false"
            placeholder="Pilih Status Baru"
            :options="statusOption"
          />
        </div>
        <div class="col-md-2">
          <b-button size="sm" class="btn-primary" @click="saveStatus()"
            >Simpan</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import ModalDetail from "@/view/components/citizen-report/ModalDetail.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moment from "moment";

export default {
  components: {
    ModalDetail,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        start_date: moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        end_date: moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        category_id: "",
      },
      // Pagination
      perPage: 5,
      currentPage: 1,
      totalRows: 60,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tgl. Aduan",
          sortable: true,
        },
        {
          key: "citizen_name",
          label: "Nama Penduduk",
          sortable: true,
        },
        {
          key: "category_name",
          label: "Jenis Pengaduan",
          sortable: true,
        },
        {
          key: "title",
          label: "Judul",
        },
        {
          key: "status_name",
          label: "Status",
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      report_category: {},
      detail: {},
      onShowPeriod: false,
      btnActive: {
        days: false,
        month: true,
        lastMonth: false,
        year: false,
        period: false,
      },
      debounceName: "",
      timeout: null,
      // status
      newStatus: "",
      dataStatusId: "",
      statusOption: [
        { label: "Diajukan", id: 1 },
        { label: "Diproses", id: 2 },
        { label: "Selesai", id: 3 },
        { label: "Ditolak", id: 4 },
      ],
      formNewStatus: {
        status: "",
        _method: "put",
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    showModal(data) {
      this.$bvModal.show("detail-citizen-report");
      this.detail = data;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&category_id=${this.filter.category_id}&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "citizen-report",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

      console.log(this.items);
    },

    filterByCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.category_id = "";
      }
      this.pagination();
    },

    statusOnClick(status, id) {
      this.newStatus = status;
      this.dataStatusId = id;
      this.$bvModal.show("modal-status");
    },

    async getCategoryOption() {
      let response = await module.setTreeSelect("category-citizen-report");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.report_category = response.data;
        this.report_category.unshift({
          label: "Pilih Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("citizen-report/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async saveStatus() {
      // Make Request
      this.formNewStatus.status = this.newStatus;
      let response = await module.submit(
        this.formNewStatus,
        `/citizen-report/update-status/${this.dataStatusId}`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$bvModal.hide("modal-status");
        this.pagination();
      }
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }

      if (type != "period") {
        if (this.filter.start_date != "" || this.filter.end_date != "") {
          this.currentPage = 1;
        } else {
          this.currentPage = this.lastCurrentPage;
        }

        this.pagination();
      }
    },

    resetFilter() {
      // set time
      this.filter.start_date = moment()
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      this.filter.end_date = moment()
        .endOf("month")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      // set btn Active
      this.btnActive.days = false;
      this.btnActive.month = true;
      this.btnActive.lastMonth = false;
      this.btnActive.year = false;
      this.btnActive.period = false;

      this.onShowPeriod = false;

      this.filter.category_id = "";
      this.filter.name = "";

      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("citizen-report/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },

    // debounced algorithm
    // debounce(func, wait = 2000, immediate) {
    //   var timeout;

    //   return function executedFunction() {
    //     var context = this;
    //     var args = arguments;

    //     var later = function () {
    //       timeout = null;
    //       if (!immediate) func.apply(context, args);
    //     };

    //     var callNow = immediate && !timeout;

    //     clearTimeout(timeout);

    //     timeout = setTimeout(later, wait);

    //     if (callNow) func.apply(context, args);
    //   };
    // },
  },
  computed: {
    filterbyName: {
      get() {
        return this.filter.name;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.filter.name = val;
          this.pagination();
        }, 500);
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Pengaduan Masyarakat" },
    ]);

    this.pagination();
    this.getCategoryOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
